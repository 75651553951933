.view_details_modal {
    ul {
        padding: 0;
        margin: 0;

        li {
            display: flex;
            align-items: center;

            h6 {
                width: 100%;
                max-width: 100px;
                margin-bottom: 0;
            }

            p {
                margin: 0;
                width: 100%;
                padding-left: 10px;
                text-align: left;
            }
        }
    }
}