.uplaoding_file_dropzone_modal {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: 340px;
    background-color: white;
    border-radius: 10px 10px 0 0;

    .uplaoding_file_dropzone_modal_header {
        display: flex;
        align-items: center;
        padding: 18px;
        justify-content: space-between;
        background-color: #ebe6e6;
        border-radius: inherit;

        h6 {
            margin-bottom: 0;
        }

        button {
            width: 24px;
            height: 24px;
            background-color: transparent;
            border: none;
            outline: none;
            padding: 0;

            svg {
                path {
                    fill: black;
                }
            }
        }
    }

    ul {
        padding: 0 18px 18px;
        margin: 0;

        li {
            display: flex;
            align-items: center;
            padding: 10px 0;

            h4 {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 0;
                width: 100%;

                svg {
                    width: 18px;
                    height: 18px;
                    flex-shrink: 0;
                    margin-right: 10px;

                    path {
                        fill: black;
                    }
                }

                span {
                    cursor: pointer;
                    margin-left: auto;
                }
            }
        }
    }
}