.custom_btn {
    background-color: #4b49ac;
    padding: 0 18px;
    height: 36px;
    font-size: 13px;
    font-weight: 700;
    border: 1px solid #4b49ac;
    outline: none;
    display: inline-flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: white;
    line-height: 1;
    border-radius: 5px;
    transition: 200ms ease-in-out;

    svg {
        margin-right: 8px;
        width: 16px;
        height: 16px;
        flex-shrink: 0;

        path {
            transition: 200ms ease-in-out;
            fill: white;
        }
    }

    .spinner-border {
        width: 18px;
        height: 18px;
    }

    &.gray_btn {
        background-color: #ebe6e6;
        border-color: #ebe6e6;
        color: black;

        svg {
            path {
                fill: black;
            }
        }
    }

    &:disabled {
        opacity: 0.7;
        pointer-events: none;
    }
}