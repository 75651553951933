.breadcrumb{
    display: flex;
    align-items: baseline;
}
.bredcrums_files{
    margin: 0px;
}
.breadcrumb a{
    font-size: 16px;
    font-weight: 600;
    color: black;
}