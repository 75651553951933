.rocketShips_select {
    .select__control {
        width: 200px;
    }

    .select__menu {
        width: 240px;
    }

    .select__menu-list {
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }
}