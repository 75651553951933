.modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: absolute;
    top: 50%;
    right: 50%; */
}

.modal-content {
    max-width: 600px;
    width: 100%;
    padding: 2.25rem 2.5rem;
    border: 1px solid #dbdfea;
    border-radius: 5px;

}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    font-size: 1.25rem;
    font-weight: 500;
}

.modal-body input {
    width: 100%;
    border: 1px solid #dbdfea;
    background-color: rgb(246, 245, 248);
    border-radius: 5px;
    margin-bottom: 20px;
}

.upload_btn_modal_input {
    display: block;
    width: 100%;
    height: 60px;
    border: 1px dashed black;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload_btn_modal_input input {
    opacity: 0;
    width: 0;
    height: 0;
}

.modal-body h4 {
    font-weight: 500;
    font-size: 14px;
}

.uploaded_files {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border-radius: 4px;
    border: 1px solid #e5e9f2;
    margin-bottom: 10px;
}

.uploaded_files_inner {
    display: flex;
    align-items: center;
}

.uploaded_files_inner img {
    width: 48px;
    height: 48px;
}

.uploaded_files_descrip h5 {
    margin: 0px;
    padding-bottom: 2px;
    font-size: 0.875rem;
}

.uploaded_files_descrip h6 {
    margin: 0px;
    padding-top: 2px;
    font-size: 0.700rem;
    color: #8094ae;
    font-weight: 400;
}

.modal-footer {
    margin-top: 1.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

button.btn.btn-secondary {
    display: inline-flex;
    align-items: center;
    font-size: 0.85rem;
    font-weight: 500;
    line-height: 1;
    border: none;
    background: transparent;
    transition: all 0.3s ease;
    white-space: nowrap
}

button.btn.btn-secondary:hover {
    background-color: gray;
    max-width: 96px;
    width: 100%;
    height: 36px;
    border-radius: 5px;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s ease-in-out;
}

button.btn.btn-primary {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.85rem;
    font-weight: 600;
    line-height: 1;
    border: none;
    background: transparent;
    transition: all 0.3s ease;
    white-space: nowrap;
    color: #fff;
    background-color: #2263b3;
    max-width: 96px;
    width: 100%;
    height: 36px;
    border-radius: 5px;
}