.meain_section {
    display: flex;
}

.sidebar {
    max-width: 268px;
    width: 100%;
    padding: 16px;
    border: 1px solid#dbdfea;
}


.sidebar_feautres {
    border-radius: 3px;
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 0.5rem 0.75rem;
    gap: 20px;
    margin-bottom: 5px;

    &.active {
        background: #e4ecf6;
    }
}

.sidebar_feautres li {
    margin-left: 0.25rem;
    font-size: 0.875rem;
    color: #526484;
    font-weight: 500;
    line-height: 1.5rem;

}

.files_ul {
    margin: 0px;
}

.files_ul li {
    font-size: 0.800rem;
    text-align: start;
    font-weight: 500;
    line-height: 1.375rem;
    padding: 5px 0px 5px 14px;

}

.files_ul li a {
    color: #526484;

}

.meainbar {
    width: 100%;
    position: relative;
}

.meainbar_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1.25rem;
    border: 1px solid #dbdfea;
    background-color: #fff;
    border-left: 0px;
}

.meainbar_input {
    padding: 0.4375rem 1rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.25rem;
    color: #3c4d62;
    background-color: #fff;
    background-clip: padding-box;
    /* border: 1px solid #dbdfea; */
    appearance: none;
    border-radius: 4px;
    /* transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
    position: relative;
    width: 100%;
}

.meainbar_input svg {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0%, -50%);
}

.meainbar_input input {
    border: none;
    padding: 0px 30px 0px 30px;
    outline: none;
    width: 100%;
    box-sizing: border-box;
}

.meainbar_btns {
    display: flex;
    align-items: center;
}

.meainbar_btns_dropdowon {
    .dropdown-toggle.btn.btn-primary {
        background-color: #4b49ac;
        padding: 0 18px;
        height: 36px;
        font-size: 13px;
        font-weight: 700;
        border: 1px solid #4b49ac;
        outline: none;
        display: inline-flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        color: white;
        line-height: 1;
        border-radius: 5px;
        transition: 200ms ease-in-out;

        svg {
            margin-right: 8px;
        }

        &::after {
            content: unset;
        }
    }

    .dropdown-menu {
        padding: 6px;

        .custom_btn {
            justify-content: flex-start;
            padding: 0;
            text-align: left;
            padding: 6px 10px;
            display: flex;
            background-color: transparent;
            width: 100%;

            &:not(:last-child) {
                margin-bottom: 6px;
            }

            &:hover {
                color: white;
                background-color: #4b49ac;
                border: 1px solid #4b49ac;

                svg {
                    path {
                        fill: white;
                    }
                }
            }
        }
    }
}

.meainbar_btns li {
    padding: 0px 8px;
}

.meainbar_btns a {
    display: flex;
    align-items: center;
    padding: 9px 18px;
}

.meainbar_btns a span {
    padding-left: 8px;
    margin-left: 4px;
    font-size: 14px;

}

.meainbar_btns a svg {
    width: 18px;
}

.btn_one {
    background-color: #ebe6e6;
    color: black;
    border-radius: 5px;
    display: flex;
    align-items: center;
    transition: 200ms ease-in-out;
}

.btn_one:hover {
    background-color: #2263b3;
    color: white;
}

.btn_two {
    display: flex;
    align-items: center;
    background-color: #2263b3;
    color: white;
    border-radius: 5px;
}

.btn_one,
.btn_two {
    cursor: pointer;
    height: 36px;
    padding: 0 18px;
}

.btn_one span,
.btn_two span {
    margin-left: 8px;
    line-height: 1;
}

.meainbar_inner_two {
    padding: 28px;
    background-color: rgb(246, 245, 248);
    min-height: 100dvh;
}

.heading {
    margin: 0px;
    text-align: start;
    padding-bottom: 1rem;
    font-weight: 500;
    font-size: 30px;
}

.quick_access {
    display: flex;
    justify-content: space-between;
}

.quick_access h4 {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
}

.quick_access a {
    font-size: 14px;
}

.box {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
}

.grid_card_box_outer {
    padding: 8px;
    width: 20%;
    flex-shrink: 0;
}

.box_inner {
    height: 100%;

    /* max-width: 245px;
    width: 100%; */
    /* margin: 8px; */
    text-align: center;
    padding: 32px 0px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #dbdfea;
    position: relative;
    cursor: pointer;
}

.cross_icon {
    position: absolute;
    right: 5%;
    top: 5%;
    opacity: 0;
    transition: opacity 0.3s ease;
    padding: 8px;
}

.box_inner:hover .cross_icon {
    opacity: 1;
    border-radius: 100%;
}

.cross_icon:hover {
    background-color: #e4dede;
}

.dots_icon {
    position: absolute;
    right: 10%;
    top: 10%;
    width: 40px;
}

.files_list .dots_icon {
    right: 0%;
    top: 0%;
    position: relative;
}

.dots_icon .dropdown-toggle.btn.btn-primary {
    background-color: transparent !important;
    border: none !important;
    padding: 0;
    box-shadow: none !important;
    width: 40px;
    height: 40px;
    color: black;
}

.dots_icon .dropdown-menu {
    left: unset !important;
    right: 0 !important;
    padding: 10px;
}


.dots_icon .dropdown-menu button {
    padding: 4px 10px;
    border-radius: 5px;
    display: block;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    text-align: left;
    display: flex;
    align-items: center;
}

.dots_icon .dropdown-menu button:hover {
    background-color: #ebe6e6;
}

.dots_icon .dropdown-menu button svg {
    width: 18px;
    flex-shrink: 0;
    margin-right: 8px;
    height: 18px;
}

.dots_icon .dropdown-menu button svg path {
    fill: black;
}

.dots_icon .dropdown-menu button:not(:last-child) {
    margin-bottom: 10px;
}

.dots_icon .dropdown-toggle::after {
    content: unset;
}

.box_inner h3 {
    margin: 0px;
    font-weight: 400;
    font-size: 15px;
    margin-top: 8px;
}

.box_inner h5 {
    margin: 10px 0px;
    opacity: 0.5;
    font-weight: 400;
}

.box_img img {
    width: 100%;
    height: 100%;
}

.box_img {
    width: 80px;
    height: 70px;
    margin: auto;
    margin-bottom: 8px;
}

.browse_files {
    padding: 40px 0px 20px 0px;
}

.last_opened {
    text-align: start;
    font-size: 13px;
    font-weight: 400;
    margin: 30px 0px 5px 0px;
}

.view_second {
    padding-bottom: 50px;
}

.view_second h5 {
    border-top: 1px solid #e5e9f2;
    font-size: 12px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    padding: 16px 0 8px 0px;
    margin: 0;
    text-align: start;
}

.grid_list_card_outer {
    padding: 8px;
    width: 20%;

    @media (max-width: 1479px) {
        width: 25%;
    }

    @media (max-width: 1283px) {
        width: 33%;
    }

    @media (max-width: 1023px) {
        width: 50%;
    }
}

.view_second_box {
    padding: 16px;
    position: relative;
    cursor: pointer;
    background: #fff;
    border-radius: 4px;
    height: 100%;
    border: 1px solid #dbdfea;
}

.view_second_inner {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -8px;
}

.view_second_box_des {
    font-weight: 400;
    text-align: left;
    word-break: break-all;
    font-size: 15px;
    color: #364a63;
    margin-left: 10px;
    padding-right: 50px;
}

.view_second_box_image {
    flex-shrink: 0;
    width: 100%;
    max-width: 40px;
}

.view_second_box_top {
    display: flex;
    align-items: center;
}

.options_icon {
    position: absolute;
    top: 16px;
    right: 16px;
}

.view_second_box_about {
    font-size: 12px;
    color: #8094ae;
    text-align: start;
}

.heading_top {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

@media (max-width:992px) {
    .heading_top {
        justify-content: space-between;
    }
}

.heading_top h4 {
    margin: 0px;
    font-weight: 500;
    font-size: 0.8125rem;
}

.heading_top_first {
    width: 50%;
    text-align: start;
}

.heading_top_second {
    width: 17%;
    text-align: start;
}

@media (max-width:992px) {
    .heading_top_second {
        display: none;
    }
}

.heading_top_third {
    width: 17%;
    text-align: center;
}

@media (max-width:992px) {
    .heading_top_third {
        display: none;
    }
}

.heading_top_fourth {
    width: 17%;
    text-align: end;
}

.files_list_inner {
    display: flex;
    align-items: center;
    gap: 15px;
}

@media (max-width:992px) {
    .files_list_inner {
        width: 100%;
    }
}

.files_list_inner input {
    width: 18px;
    height: 18px;
    border: 2px solid #fff;
    ;
}

.file_list_img {
    width: 32px;
}

.files_list {
    padding: 2px 20px;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 3px;
}

.heading_title {
    padding-bottom: 10px;
}

.files_list_inner p {
    font-weight: 500;
    font-size: 15px;
    color: #364a63;
}

.time {
    font-size: 15px;
    font-weight: 300;
    color: #364a63;
}

.head_icon {
    margin-right: 20px;
}

.author {
    font-size: 12px;
    padding-top: 3px;
    color: #8094ae;
}

.view_second_file .view_action_btns {
    display: flex;
    align-items: center;
    gap: 10px;

    .channel_select {
        width: 150px;
    }

    .label_select {
        width: 250px;
    }

    .order_select {
        width: 200px;
    }

    .search_box {
        position: relative;

        .search_box_icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 10px;
        }

        .search_input {
            height: 36px;
            padding: 0 20px 0 40px;
            border: 1px solid hsl(0, 0%, 80%);
            border-radius: 4px;
            outline: none;
        }
    }
}

.view_second_file .view_action_btns button svg {
    margin: 0;
}

.drag_zone {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 1000;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.file_list_img {
    &.clickable {
        cursor: pointer;
        
        &:hover {
            opacity: 0.8;
        }
    }
}

.modal-content {
    border-radius: 8px;
    
    .modal-header {
        border-bottom: 1px solid #dbdfea;
        padding: 1rem 1.5rem;
        
        .modal-title {
            font-size: 1.1rem;
            font-weight: 500;
        }
        
        .btn-close {
            padding: 1rem;
            margin: -1rem -1rem -1rem auto;
            
            &:focus {
                box-shadow: none;
            }
        }
    }
    
    .modal-body {
        padding: 1.5rem;
        
        img {
            border-radius: 4px;
            box-shadow: 0 0 20px rgba(0,0,0,0.1);
        }
    }
}

.list_header {
    display: flex;
    padding: 10px 15px;
    background-color: #f5f6fa;
    border-bottom: 1px solid #e5e9f2;
    font-weight: 500;
    color: #364a63;

    .heading_top_first {
        flex: 2;
    }

    .heading_top_second {
        flex: 1;
    }
}