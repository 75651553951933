body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


button {
  cursor: pointer;
}

.swal2-confirm,
.swal2-deny,
.swal2-cancel {
  background-color: #4b49ac;
  padding: 0 18px;
  height: 36px;
  font-size: 13px;
  font-weight: 700;
  border: 1px solid #4b49ac;
  outline: none;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: white;
  line-height: 1;
  border-radius: 5px;
  transition: 200ms ease-in-out;
}

.custom_breadcrumbs {
  .breadcrumb {
    align-items: center;
  }

  .breadcrumb-item {

    &::before {
      content: unset;
    }

    a {
      display: flex;
      align-items: center;
      line-height: 24px;
      height: 24px;

      svg {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        margin-right: 08px;
        margin-bottom: -2px;
      }
    }
  }
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}