.header {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    padding: 10px 0;
}

li {
    list-style: none;
}

img {
    width: 100%;
    height: 100%;
}

a {
    text-decoration: none;
}

.logo {
    height: 36px;
}

.menu {
    display: flex;
    margin-left: 122px;
}


.menu li {
    font-size: 1rem;
    text-align: start;
    font-weight: 500;
    line-height: 1.375rem;
    padding: 5px 20px 5px 14px;
    color: #526484;
}

.menu li a {
    color: #526484;
}

.contact {
    display: flex;
}

.contact li img {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    /* padding: 10px; */
}

.profile_dropdown {
    .dropdown-toggle {
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        background-color: transparent !important;
        padding: 0;
    }

    .dropdown-menu {
        left: unset !important;
        right: 0 !important;
        padding: 10px;

        p {
            display: flex;
            align-items: center;
            font-weight: 500;

            img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                object-fit: cover;
                margin-right: 8px;
                flex-shrink: 0;
            }
        }
    }
}