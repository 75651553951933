.auth_failed {
    min-height: 100dvh;
    display: flex;
    align-items: center;
    padding: 40px 0;

    h3 {
        // border-bottom: 2px solid $primary;
        padding-bottom: 4px;
        font-size: 16px;
        display: inline-flex;
    }

    h1 {
        font-size: 74px;
        font-weight: 700;
        // color: $white;
        margin: 10px 0;

        @media (max-width: 1479px) {
            font-size: 60px;
        }

        @media (max-width: 1399px) {
            font-size: 50px;
        }

        @media (max-width: 1199px) {
            font-size: 40px;
        }

        @media (max-width: 767px) {
            font-size: 30px;
            margin: 16px 0;
        }
    }

    p {
        // color: $text;
        font-size: 2px;

        @media (max-width: 1479px) {
            font-size: 18px;
        }

        @media (max-width: 1199px) {
            font-size: 16px;
        }

        @media (max-width: 767px) {
            font-size: 14px;
        }
    }

    a {
        display: inline-flex;
        align-items: center;
        font-size: 16px;
        // color: $primary;
        margin-top: 30px;

        @media (max-width: 767px) {
            font-size: 14px;
            margin-top: 20px;
        }

        svg {
            width: 24px;
            height: auto;
            transition: 200ms ease-in-out;

            // path {
            // fill: $primary;
            // }
        }

        &:hover {
            svg {
                transform: translateX(0.6rem);
            }
        }
    }
}